$(function () {
  $('.fa-eye').each(function () {
    $(this).on('click', function () {
      var passwordInput = $(this).prev(".c-field_form_text");
      if (passwordInput.attr('type') === 'password') {
        passwordInput.attr('type', 'text');
        $(this).removeClass('fa-eye').addClass('fa-eye-slash');
      } else {
        passwordInput.attr('type', 'password');
        $(this).removeClass('fa-eye-slash').addClass('fa-eye');
      }
    });
  });
});
